import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { messages as en } from '@locales/en/messages';
import { messages as pl } from '@locales/pl/messages';
import { FC } from 'react';
import React from 'react';

i18n.load('pl', pl);
i18n.load('en', en);

i18n.activate('pl');

const LanguageProvider: FC = ({ children }) => {
	return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

export default LanguageProvider;
